import axios from "axios";
import fslightbox from "fslightbox";

const itemsWrap = document.querySelector(".js-items-wrap");
const buttonLoadMore = document.querySelector(".js-btn-load-more");

document.addEventListener(
    "click", function (event) {

        if (!event.target.matches(".js-btn-load-more")) return;
        event.stopPropagation();
        event.preventDefault();

        let button = event.target;
        if (!button.disabled) {
            button.disabled = true;
            button.classList.add("loading");

            let nextPageLink = button.getAttribute("data-next-page-link");
            let nextPageNumber = parseInt(button.getAttribute("data-next-page-number"));
            let currentPage = parseInt(button.getAttribute("data-current-page"));
            let isLastPage = (button.getAttribute("data-last-page").toString() === '1');

            if (((currentPage + 1) === nextPageNumber) && !isLastPage) {
                axios
                    .get(nextPageLink, {
                        params: {
                            api: true,
                        },
                    })
                    .then(function (response) {
                        // handle success
                        let responseData = response.data;
                        handleUrl(responseData.currentPage);

                        itemsWrap.innerHTML += responseData.html;

                        button.setAttribute("data-next-page-link", responseData.nextPageUrl);
                        button.setAttribute("data-current-page", responseData.currentPage);
                        button.setAttribute("data-next-page-number", responseData.nextPageNumber);

                        if (responseData.last) {
                            toggleButton(button, false);
                            button.setAttribute("data-last-page", '1');
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                    .then(function () {
                        // always executed
                        button.disabled = false;
                        button.classList.remove("loading");

                        refreshFsLightbox();
                    });
            }
            else {
                let page = currentPage + 1;

                handleUrl(page);
                toggleListItems(page);

                button.setAttribute("data-current-page", page);

                button.disabled = false;
                button.classList.remove("loading");

                if (button.getAttribute("data-current-page") < button.getAttribute("data-next-page-number")) {
                    toggleButton(button, true);
                } else {
                    toggleButton(button, false);
                }
            }
        }

        return false;
    },
    false
);

function handleUrl(page) {
    // Push params to browser history URL
    const url = new URL(window.location);
    const state = { strana: page };

    // Push page number to browser history
    url.searchParams.set("strana", page);

    window.history.pushState(state, "", url);
}

function toggleButton(button, toggle) {
    // toggle: false = hide | true = show
    if (toggle) {
        button.parentNode.style.display = "block";
    } else {
        button.parentNode.style.display = "none";
    }
}

function toggleListItems(page) {
    itemsWrap.forEach((item) => {
        // Zobrazenie a schovanie poloziek, ktore su mimo rozsah aktualnej strany
        if (item.getAttribute("data-page") > page) {
            item.style.display = "none";
        } else {
            item.style.display = "block";
        }
    });
}

// Akcia ktora sa zavola po kliknuti na back a forward button v prehliadaci
window.addEventListener("popstate", function (e) {
    const currentPage = e.state ? e.state.strana : 1;

    toggleListItems(currentPage);
    buttonLoadMore.setAttribute("data-current-page", currentPage);

    if (buttonLoadMore.getAttribute("data-current-page") < buttonLoadMore.getAttribute("data-next-page-number")) {
        toggleButton(buttonLoadMore, true);
    } else {
        toggleButton(buttonLoadMore, false);
    }
});

function checkUrlOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("strana");

    if (page) {
        handleUrl(page);
    }
}

checkUrlOnInit();
